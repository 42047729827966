<template>
  <b-modal
    id="wameed-modal"
    v-model="show"
    class="warnings-modal ahmed"
    title=""
    no-fade
    hide-header
    scrollable
    dialog-class="fixed-modal-width"
  >
    <div class="align-items-center w-100">
      <div
        class="
          d-flex
          flex-column
          justify-content-between
          align-items-center
          w-100
          mx-auto
        "
      >
        <b-avatar
          :variant="'light-' + variant2 "
          rounded
          size="76"
          class="mb-5"
        >
          <component :is="iconName" />
        </b-avatar>

        <h4 class="text-med-20 py-0 text-dark">
          {{ title }}
        </h4>
        <h4 class="text-reg-16 py-0 text-center text-font-secondary">
          {{ subTitle }}
        </h4>
      </div>
    </div>
    <template slot="modal-footer">
      <div class="d-flex justify-content-between align-items-center w-100">
        <wameed-btn
          :variant="variant"
          size="sm"
          :title="btnTitle"
          classes="text-book-18 py-5 mx-2 rounded-10 px-4 mt-0 text-white"
          @submitAction="submitAction()"
        />
        <wameed-btn
          variant="disable"
          size="sm"
          :title="$t('common.cancel')"
          classes="text-book-18 py-5 mx-2 rounded-10 px-4 mt-0 text-font-secondary"
          @submitAction="show = false"
        />
      </div>
    </template>
  </b-modal>
</template>

<script>
import WameedBtn from './WameedBtn.vue';

export default {
  components: { WameedBtn },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: null,
    },
    variant2: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: null,
    },
    iconName: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    subTitle: {
      type: String,
      default: null,
    },
    btnTitle: {
      type: String,
      default: null,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    submitAction() {
      this.$emit('submitAction');
    },
  },
};
</script>

<template>
  <div class="wameed-dashboard-page-content mb-5">
    <page-header
      :title="$t('common.financial_title')"
      :sub-title="$t('common.financial_subtitle')"
      btn
      variant="main"
      :btn-title="$t('common.add_new_process')"
      btn-prepend-icon="add-circle-icon"
      classes="text-bold-15 text-white w-cus-sm-100"
      @btnAction="showaddNewProcessModal = true"
    />

    <div>
      <filter-header
        :content="content"
        @applyFilter="applyFilter"
        @resetFilter="resetFilter"
        @orderApplicants="orderApplicants"
      />
    </div>
    <section
      v-if="getAllFinancial"
      class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
      "
    >
      <div class="d-flex flex-wrap flex-1 px-2">
        <b-col
          lg="12"
          md="12"
          class="px-0"
        >
          <div class="w-table d-flex flex-column">
            <w-tables
              v-if="getAllFinancial"
              :per-page="filterData.per_page"
              :items="getAllFinancial"
              :fields="fields"
              :status="status"
              :custimized-items="[
                { name: 'amount' },
                { name: 'hr_job_title' },
                { name: 'platform_dues' },
                { name: 'created_at' },
                { name: 'type' },
                { name: 'action' },
              ]"
            >
              <template
                slot="amount"
                slot-scope="{ data }"
              >
                <div class="d-flex">
                  <span
                    class="text-font-main mx-3"
                  >{{ data.item.amount }} {{ $t('common.sr_currency') }}</span>
                </div>
              </template>
              <template
                slot="hr_job_title"
                slot-scope="{ data }"
              >
                <div class="d-flex">
                  <span
                    class="text-font-main mx-3"
                  >({{ data.item.id }}) {{ data.item.presenter_name }} -
                    {{ data.item.hr_job_title }}
                  </span>
                </div>
              </template>
              <template
                slot="platform_dues"
                slot-scope="{ data }"
              >
                <div class="d-flex">
                  <span
                    v-if="data.item.platform_dues"
                    class="text-font-main mx-3"
                  >{{ data.item.platform_dues }}%
                  </span>
                  <span
                    v-else
                    class="text-font-main mx-3"
                  >-------- </span>
                </div>
              </template>
              <template
                slot="created_at"
                slot-scope="{ data }"
              >
                <div class="d-flex">
                  <span class="text-font-main mx-3">{{
                    formatedDate(data.item.created_at)
                  }}</span>
                </div>
              </template>
              <template
                slot="type"
                slot-scope="{ data }"
              >
                <div class="d-flex">
                  <span
                    v-if="data.item.type == 1"
                    class="text-font-main mx-3"
                  >
                    <administrative-fees-icon class="mr-3" />
                    {{ $t('common.administrative_fees') }}</span>
                  <span
                    v-if="data.item.type == 2"
                    class="text-font-main mx-3"
                  >
                    <type-maturity-from-company-icon class="mr-3" />
                    {{ $t('common.entitlement_from_company') }}</span>
                  <span
                    v-if="data.item.type == 3"
                    class="text-font-main mx-3"
                  >
                    <type-pay-employee-icon class="mr-3" />
                    {{ $t('common.pay_employee') }}</span>
                </div>
              </template>
              <template
                slot="action"
                slot-scope="{ data }"
              >
                <b-dropdown
                  variant="background"
                  toggle-class="text-decoration-none rounded-10 px-2 py-2 "
                  menu-class="rounded-14"
                  no-caret
                  dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon class="mx-3 my-1" />
                  </template>
                  <b-dropdown-item
                    link-class="py-0min-height: 52px;"
                    @click="downloadInvoiceFun(data.item.id)"
                  >
                    <span class="text-regular-14 text-font-secondary">
                      <bill2-icon class="mx-2" />
                      {{ $t('common.show_bill') }}
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </w-tables>
            <wameed-no-data
              v-if="getAllFinancial && getAllFinancial.length < 1"
              icon="nodata-icon"
              :title="$t('offers_page.no_data')"
              :sub-title="$t('offers_page.no_data_text')"
            />
          </div>
        </b-col>

        <b-col
          v-if="getAllFinancial && getAllFinancial.length > 0"
          lg="12"
          md="12"
          class="px-0"
        >
          <wameed-pagination
            v-model="filterData.page"
            :page="filterData.page"
            :total-items="getTotalFinancial.totalItems"
            :per_page="filterData.per_page"
            @changePage="changePage"
            @changeCurrentPage="changeCurrentPage"
          />
        </b-col>
      </div>
    </section>
    <validation-observer
      ref="form"
      #default="{ invalid }"
      tag="form"
      style="overflow: visible"
    >
      <b-modal
        id="wameed-modal"
        v-model="showaddNewProcessModal"
        modal-class="not-m-modal"
        header-class="py-5 px-5"
        body-class="py-5 px-5"
        title=""
        no-fade
        scrollable
        hide-header
      >
        <div class="align-items-center w-100">
          <div
            class="
              d-flex
              flex-column
              justify-content-between
              align-items-center
              w-75
              mx-auto
              mt-5
            "
          >
            <b-avatar
              :variant="'light-main'"
              rounded
              size="76"
              class="mb-5"
            >
              <financial-process-icon />
            </b-avatar>

            <h4 class="text-med-20 py-0 text-dark">
              {{ $t('common.add_new_financial_process_title') }}
            </h4>
            <h4 class="text-reg-16 py-0 text-center text-font-secondary">
              {{ $t('common.add_new_financial_process_subtitle') }}
            </h4>
          </div>
        </div>
        <wameed-form refs="addOfferForm">
          <template
            slot="form-content"
            slot-scope="{ invalid }"
          >
            <div class="p-0">
              <b-form-group :label="$t('common.process_type')">
                <b-form-radio-group
                  id="radio-slots"
                  v-model="submitData.type"
                  name="radio-options-slots"
                ><div class="row no-gutters">
                  <div class="col-6 pr-3">
                    <div
                      class="account-type_radio"
                      :class="submitData.type == '2' ? 'radio_checked' : ''"
                    >
                      <b-form-radio
                        class="text-bold-12 text-font-sub"
                        value="2"
                      >
                        {{ $t('common.entitlement_from_company') }}
                      </b-form-radio>
                    </div>
                  </div>
                  <div class="col-6 pl-2">
                    <div
                      class="account-type_radio"
                      :class="submitData.type == '3' ? 'radio_checked' : ''"
                    >
                      <b-form-radio
                        class="text-bold-12 text-font-sub"
                        value="3"
                      >
                        {{ $t('common.pay_employee') }}
                      </b-form-radio>
                    </div>
                  </div>
                </div>
                </b-form-radio-group>
              </b-form-group>
            </div>
            <div class="p-0">
              <wameed-dropdown
                v-if="getAllProjects"
                id="role"
                v-model="submitData.project"
                :label="$t('common.project')"
                :placeholder="$t('common.project_placeholder')"
                classes="text-bold-14 w-100"
                value-classes="text-med-14 text-font-secondary"
                :list-title="[
                  { name: 'id', separator: '(' },
                  { name: 'hr_job_title', separator: ')' },
                  { name: 'company_name', separator: '-' },
                ]"
                variant="disable"
                :items="getAllProjects"
                text="name"
                name="role"
                rules="required"
                :multiple="false"
              />
            </div>
            <div class="p-0 append-success-color">
              <text-input
                id="amount"
                v-model="submitData.amount"
                :label="$t('orders_page.price')"
                :placeholder="$t('offers_page.price_placeholder')"
                input-classes="text-bold-14 text-font-main"
                name="amount"
                rules="required|double"
                is-append
                :append-text="$t('common.sr_currency')"
              />
            </div>
            <div class="p-0">
              <text-input
                id="invoice_id"
                v-model="submitData.invoice_id"
                :label="$t('common.invoice_number')"
                :placeholder="$t('common.invoice_number_placeholder')"
                input-classes="text-bold-14 text-font-main"
                name="invoice_id"
                rules="required|double"
              />
            </div>
          </template>
        </wameed-form>
        <template slot="modal-footer">
          <div class="d-flex justify-content-between align-items-center w-100">
            <wameed-btn
              classes="  text-med-14 text-white rounded-10"
              :title="$t('orders_page.add_on')"
              :disabled="submitData.type ? false : true"
              type="submit"
              variant="main"
              @submitAction="addNewProcess"
            />
            <wameed-btn
              classes="  text-med-14 text-font-secondary rounded-10 "
              :title="$t('common.cancel')"
              type="button"
              variant="gray"
              @submitAction="hideProcessModal()"
            />
          </div>
        </template>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import PageHeader from '@/components/PageHeader.vue';
import FilterHeader from '@/components/FilterHeader.vue';
// import ordersCard from "@/components/pages/orders/OrdersCard.vue";
import WTables from '@/components/pages/applicants/WTables.vue';
import WarningModal from '@/components/WarningModal.vue';
import { ValidationObserver } from 'vee-validate';
import WameedForm from '@/components/WameedForm.vue';
import WameedBtn from '@/components/WameedBtn.vue';
import TextArea from '@/components/TextArea.vue';
import TextInput from '@/components/TextInput.vue';
import WameedDropdown from '@/components/WameedDropdown.vue';

export default {
  components: {
    WameedPagination,
    WameedNoData,
    PageHeader,
    FilterHeader,
    // ordersCard,
    WTables,
    WameedBtn,
    WarningModal,
    ValidationObserver,
    TextArea,
    WameedForm,
    TextInput,
    WameedDropdown,
  },
  data() {
    return {
      showaddNewProcessModal: false,
      rejectedDescription: '',
      orderId: null,
      filterProjectsData: {
        status: '',
        industry_preference: '',
        page: 1,
        per_page: 'all',
        order_by: 'desc',
        search: '',
      },
      filterData: {
        type: '',
        page: 1,
        per_page: 10,
        order_by: 'desc',
        search: '',
      },
      content: [
        {
          title: 'process_type',
          key: 'type',
          id: 'id',
          name: 'title',
          selectedData: [],
          data: [
            { title: this.$i18n.t('common.administrative_fees'), id: 1 },
            { title: this.$i18n.t('common.entitlement_from_company'), id: 2 },
            { title: this.$i18n.t('common.pay_employee'), id: 3 },
          ],
        },
      ],
      fields: [
        {
          key: 'hr_job_title',
          label: this.$i18n.t('common.project'),
          sortable: true,
        },
        {
          key: 'amount',
          label: this.$i18n.t('project_page.price'),
          sortable: true,
        },
        {
          key: 'invoice_id',
          label: this.$i18n.t('common.invoice_number'),
          sortable: true,
        },
        {
          key: 'platform_dues',
          label: this.$i18n.t('common.platform_dues'),
          sortable: true,
        },

        {
          key: 'created_at',
          label: this.$i18n.t('common.date'),
          sortable: true,
        },
        {
          key: 'type',
          label: this.$i18n.t('common.process_type'),
          sortable: true,
        },
        { key: 'action', label: '', sortable: false },
      ],
      status: [
        {
          1: 'Current',
          2: 'Professional',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'success',
          2: 'warning',
          3: 'danger',
        },
      ],
      submitData: {
        type: null,
        project: null,
        amount: null,
        invoice_id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      getAllProjects: 'getAllProjects',
      getAllFinancial: 'getAllFinancial',
      getTotalFinancial: 'getTotalFinancial',
    }),
  },
  watch: {},
  created() {
    this.loadData();
    this.loadProjects(this.filterProjectsData);
  },
  methods: {
    ...mapActions({
      loadProjects: 'loadProjects',
      loadFinancial: 'loadFinancial',
      addFinancial: 'addFinancial',
      downloadInvoice: 'downloadInvoice',
    }),
    hideProcessModal() {
      this.showaddNewProcessModal = false;
      this.submitData = {
        type: null,
        project: null,
        amount: null,
        invoice_id: null,
      };
    },
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadFinancial(this.filterData);
    },
    packageDetails(id) {
      this.$router.push({
        name: 'orders-list-details',
        params: { lang: this.$i18n.locale, id },
      });
    },
    applyFilter() {
      this.content.forEach(item => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    resetFilter() {
      this.content.forEach(item => {
        item.selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    orderApplicants(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
    billDetails(id) {
      this.$router.push({
        name: 'orders-details',
        params: { lang: this.$i18n.locale, id },
      });
    },
    downloadInvoiceFun(id) {
      this.downloadInvoice(id);
    },
    formatedDate(date) {
      if (date) {
        this.moment.locale(this.$i18n.locale === 'ar' ? 'ar_SA' : 'en_US');
        return this.moment().diff(this.moment(date), 'days') > 7
          ? this.moment(date).format('DD/MM/YYYY')
          : this.moment(date).fromNow(true);
      }
      return '------';
    },
    addNewProcess() {
      this.addFinancial({
        type: this.submitData.type,
        project_id: this.submitData.project.id,
        amount: this.submitData.amount,
        invoice_id: this.submitData.invoice_id,
      }).then(() => {
        this.showaddNewProcessModal = false;
        this.submitData = {
          type: null,
          project: null,
          amount: null,
          invoice_id: null,
        };
        this.loadData();
      });
    },
  },
};
</script>
